<script setup lang="ts">
import type {
  NovaSearchAutoCompleteEmits,
  NovaSearchAutoCompleteProps,
} from './NovaSearchBarAutoComplete.types'

const emits = defineEmits<NovaSearchAutoCompleteEmits>()
const props = defineProps<NovaSearchAutoCompleteProps>()
const { keyword, keyFocusIdx } = toRefs(props)

// 검색 키워드와 매칭되는 키워드는 하이라이트 처리
const replaceKeywordHighlight = (acKeyword: string) => {
  const clear = keyword.value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // 특수문자 이스케이프
  const regex = new RegExp(`(${clear})`, 'gi')
  return acKeyword.replace(regex, '<span class="highlight">$1</span>')
}
</script>

<template>
  <div class="nova-search-auto-complete">
    <button
      v-for="(item, index) in keywords"
      :key="index"
      :class="['auto-complete-button', { on: index === keyFocusIdx }]"
      type="button"
      @click.stop="emits('onClickKeyword', item)"
    >
      <NovaIcon
        :icon="{ type: 'outline', name: 'search' }"
        :size="16"
        class="search-icon"
      />

      <span
        v-dompurify-html="replaceKeywordHighlight(item)"
        class="search-keyword"
      />
    </button>
  </div>
</template>

<style scoped lang="scss">
$keyword-highlight: #213acc;

@mixin auto-complete-button-active {
  background-color: $color-bg-1;

  @include mobile {
    background-color: hex-to-rgba($color-primary, 0.1);
  }
}

.nova-search-auto-complete {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .auto-complete-button {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    transition: background-color 0.1s ease-in-out;

    @media (hover: hover) {
      &:hover {
        @include auto-complete-button-active;
      }
    }

    &:active {
      .search-icon {
        color: $keyword-highlight;
      }
    }

    &.on {
      @include auto-complete-button-active;
    }

    .search-icon {
      flex-shrink: 0;
      color: $color-text-12;
      transition: color 0.1s ease-in-out;
    }

    .search-keyword {
      flex-grow: 1;
      @include text-style($text-body-14-regular);
      @include ellipsis(1);
      text-align: left;
      letter-spacing: normal;
      color: $color-black;

      &:deep() {
        span.highlight {
          color: $keyword-highlight;
        }
      }
    }
  }
}
</style>
