<script setup lang="ts">
import type { CSSProperties } from 'vue'
import type {
  NovaSearchPanelEmits,
  NovaSearchPanelProps,
} from './NovaSearchPanel.type'

const emits = defineEmits<NovaSearchPanelEmits>()
defineProps<NovaSearchPanelProps>()
const layoutStore = useLayoutStore()
const { responsive, headerRect, bottomTabBarRect, layers } =
  storeToRefs(layoutStore)
const openSearchStore = useOpenSearchStore()
const { autoComplete, autoCompleteKeyword, keyword } =
  storeToRefs(openSearchStore)
const novaSearchPanelRef = ref<HTMLDivElement>()
const isOpen = computed(() => layers.value.includes(LayerType.SEARCH_PANEL))
const styles = computed<CSSProperties>(() => {
  const mobile: CSSProperties = {}
  const others: CSSProperties = {
    top: `calc(100% + ${useGetStyleSize(isOpen.value ? 26 : 36)})`,
    maxHeight: `calc(100vh - ${useGetStyleSize(
      (headerRect.value?.height || 0) +
        (bottomTabBarRect.value?.height || 0) +
        100
    )})`,
  }

  switch (responsive.value) {
    case 'mobile':
      return mobile
    default:
      return others
  }
})

const handleOnCloseSearchPanel = () => {
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}

const handleOnSearch = (searchKeyword: string) => {
  emits('onSearch', searchKeyword)
}
onMounted(() => {
  // window.addEventListener('resize', handleOnCloseSearchPanel)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleOnCloseSearchPanel)
})
</script>

<template>
  <div
    ref="novaSearchPanelRef"
    :class="['nova-search-panel', { on: isOpen }]"
    :style="styles"
  >
    <div class="dim" @click="handleOnCloseSearchPanel" />
    <div class="inner">
      <NovaSearchBarAutoComplete
        v-if="isSearchInputFocus && autoCompleteKeyword && autoComplete.length"
        :keywords="autoComplete"
        :keyword="keyword"
        :key-focus-idx="keyFocusIdx"
        @on-click-keyword="handleOnSearch"
      />

      <NovaSearchBarIntro
        v-show="
          !(isSearchInputFocus && autoCompleteKeyword && autoComplete.length)
        "
        :is-init="isOpen"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.nova-search-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 375px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  > .inner {
    height: 100%;
    max-height: inherit;
    border-radius: 15px;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 #00000014;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &.on {
    pointer-events: auto;

    > .inner {
      opacity: 1;
    }
  }

  @include mobile {
    position: relative;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    background-color: transparent;

    > .dim {
      display: none;
    }

    > .inner {
      box-shadow: none;
      border-radius: 0;
      height: 100%;
      background-color: transparent;
    }
  }
}
</style>
