<script setup lang="ts">
import type {
  NovaBoxRecentSearchesItemEmits,
  NovaBoxRecentSearchesItemProps,
} from './NovaBoxRecentSearchesItem.types'

const { gtEvent } = useGoogleTag()
const emit = defineEmits<NovaBoxRecentSearchesItemEmits>()
const props = withDefaults(defineProps<NovaBoxRecentSearchesItemProps>(), {
  theme: 'bar',
  on: false,
})
const searchStore = useSearchStore()
const { keyword: searchKeyword } = storeToRefs(searchStore)
const openSearchStore = useOpenSearchStore()
const { keyword: openSearchKeyword } = storeToRefs(openSearchStore)

const handleOnSearch = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 결과 > 최근 검색어',
    eventLabel: props.keyword,
    eventSlot: '',
    eventI18nAddr: props.keyword,
    eventComponent: 'Button',
  })
  emit('onSearch')

  openSearchStore.updateKeyword(searchKeyword.value)
  openSearchStore.fetchAutoComplete({
    keyword: openSearchKeyword.value,
  })
}

const handleOnRemove = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 결과 > 최근 검색어 삭제',
    eventLabel: 'close-compact',
    eventSlot: props.keyword,
    eventI18nAddr: 'close-compact',
    eventComponent: 'Button',
  })
  emit('onRemove')
}
</script>

<template>
  <div
    type="button"
    :class="['recent-search-item', `theme-${theme}`, { on }]"
    @click="handleOnSearch"
  >
    <span class="label">{{ keyword }}</span>
    <NovaButtonIcon
      :icon="{ type: 'outline', name: 'close-compact' }"
      :size="20"
      class="btn-remove"
      @click.stop="handleOnRemove"
    />
  </div>
</template>

<style lang="scss" scoped>
@mixin recent-search-item-active {
  background-color: $color-bg-1;
}

.recent-search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 6px 7px;
  cursor: pointer;
  border-radius: 7px;
  user-select: none;
  transition: background-color 0.12s ease-in-out;

  &.on {
    @include recent-search-item-active;
  }

  @media (hover: hover) {
    &:hover {
      @include recent-search-item-active;
    }
  }

  > .label {
    @include text-style($text-body-13-regular);
    @include ellipsis(1);
    color: $color-text-2;
  }

  > .btn-remove {
    color: $color-text-3;
  }

  &.theme-badge {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    width: auto;
    max-width: 100%;
    height: 28px;
    padding: 5px 8px;
    border-radius: 14px;
    border: 1px solid #b5bedb;

    > .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: -0.025em;
      text-align: left;
      color: $color-black;
      @include ellipsis(1);
    }

    > .btn-remove {
      :deep(:before) {
        color: #b5bedb;
      }

      @media (hover: hover) {
        &:hover {
          :deep(:before) {
            color: #3f4354;
          }
        }
      }
    }
  }
}
</style>
