/**
 * 자동완성 리스트 키 이벤트
 */
export enum NovaAutoCompleteKeywordsDirection {
  up,
  down,
}

/**
 * 검색바 Emits
 */
export type NovaSearchBarEmits = {}
