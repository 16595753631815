<script setup lang="ts">
import { ROUTES, routeSplitPrefix } from '@configs'
import { LayerType } from '@store'

// const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()
// const { layers } = storeToRefs(layoutStore)
// const openSearchStore = useOpenSearchStore()
// const { keyword } = storeToRefs(openSearchStore)
// const isOpen = computed(() => layers.value.includes(LayerType.SEARCH_PANEL))
const headerRef = ref<HTMLElement | null>(null)
const showSearchBar = ref(false)
const router = useRouter()

// 검색 결과 페이지를 제외하고 최초 랜딩시 서치바 오픈 결정
const handleOnLadingShowSearchBar = (to: any) => {
  const routeName = String(to.name).split(routeSplitPrefix)[0]
  showSearchBar.value = routeName.includes(ROUTES.SEARCH.name)
}
// const handleOnShowSearchBar = () => {
//   gtEvent('userAction', {
//     eventCategory: '사용자',
//     eventAction: '헤더 > 검색 input 노출 버튼',
//     eventLabel: '',
//     eventSlot: '모바일',
//     eventI18nAddr: '',
//     eventComponent: 'Button',
//   })
//   showSearchBar.value = true
// }
const handleOnHideSearchBar = () => {
  showSearchBar.value = false
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}
const updateHeaderBarRect = () => {
  const headerRect = headerRef.value?.getBoundingClientRect() || null
  const headerInnerRect =
    headerRef.value?.querySelector('.inner')?.getBoundingClientRect() || null
  layoutStore.updateHeaderRect(headerRect)
  layoutStore.updateHeaderInnerRect(headerInnerRect)
}

onMounted(() => {
  updateHeaderBarRect()
  window.addEventListener('resize', updateHeaderBarRect)
  if (!useCheckMobile()) {
    window.addEventListener('resize', handleOnHideSearchBar)
  }
  router.afterEach((to) => {
    handleOnLadingShowSearchBar(to)
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateHeaderBarRect)
  if (!useCheckMobile()) {
    window.removeEventListener('resize', handleOnHideSearchBar)
  }
})
</script>

<template>
  <header
    ref="headerRef"
    :class="['header', { 'mobile-gnb-hide': layoutStore.mobileGnbHide }]"
  >
    <div class="upper">
      <div class="inner">
        <div class="inner-item left">
          <div class="bi-group">
            <NovaLogo :is-go-home="true" :responsive="true" />
          </div>
        </div>

        <div class="inner-item center"></div>

        <div class="inner-item right">
          <NovaHeaderTools />
        </div>
      </div>
    </div>

    <div class="under">
      <div class="inner">
        <div class="inner-item left">
          <NovaHeaderNavgations class="navigations" />
        </div>

        <div class="inner-item center"></div>

        <div class="inner-item right">
          <div class="header-search-bar">
            <NovaSearchBar />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $color-bg-3;
  z-index: $z-index-header;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  @include transition(all 0.2s);

  .upper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    .inner-item {
      &.right {
        padding-right: 20px;
        @include mobile {
          padding-right: 0;
        }
      }
    }
  }

  .under {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    @include mobile {
      height: 50px;
    }
  }

  .inner {
    @include inner-base;

    .inner-item-group {
      flex-grow: 1;
      display: flex;
      gap: 40px;
    }

    .inner-item {
      display: flex;
      align-items: center;

      &.left {
        @include inner-left-base;
        width: auto;
        padding-left: 20px;
      }

      &.center {
        @include inner-center-base;
      }

      &.right {
        @include inner-right-base;
        width: auto;
        max-width: 390px;
      }
    }
  }

  .bi-group {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 105px;

    @include mobile {
      width: 76px;
    }
  }

  @include tablet {
    .inner {
      max-width: $base-tablet-main-max-width;

      .inner-item {
        &.left {
          padding-left: 4px;
        }
      }
    }
  }

  @include mobile {
    .under {
      width: 0;
      height: 0;
    }

    .inner {
      padding: 0 25px;
      gap: 0;

      .inner-item {
        &.left {
          width: auto;
          padding-left: 0;
        }

        &.right {
          width: auto;
        }
      }
    }

    &.mobile-gnb-hide {
      display: none;
    }
  }

  .header-search-bar {
    min-width: 308px;
  }
}
</style>
