import * as OpenSearchType from './type'
import OpenSearchService from './service'
import { initOpenSearchState } from './init'

export * from './init'
export * from './type'
export const useOpenSearchStore = defineStore('opensearch', () => {
  const keyword = ref(initOpenSearchState.keyword)
  const autoCompleteKeyword = ref(initOpenSearchState.autoCompleteKeyword)
  const autoComplete = ref(initOpenSearchState.autoComplete)

  /**
   * 자동 완성
   * @param payload
   */
  const fetchAutoComplete = async (
    payload: OpenSearchType.AutoCompletePayload
  ) => {
    const { data } = await OpenSearchService.fetchAutoComplete(payload)
    autoComplete.value = data
  }

  /**
   * 자동 완성 리스트 클리어
   */
  const clearAutoComplete = () => {
    autoComplete.value = []
    autoCompleteKeyword.value = ''
  }

  /**
   * 오픈 검색 키워드 업데이트
   */
  const updateKeyword = (_keyword: string) => {
    keyword.value = _keyword
  }

  return {
    keyword,
    autoCompleteKeyword,
    autoComplete,
    fetchAutoComplete,
    clearAutoComplete,
    updateKeyword,
  }
})
