<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { NovaAutoCompleteKeywordsDirection } from './NovaSearchBar.types'
import { ROUTES } from '@configs'
import { LayerType } from '@store'

const { gtEvent } = useGoogleTag()
const route = useRoute()
const { t } = useI18n()
const searchStore = useSearchStore()
const layoutStore = useLayoutStore()
const { layers, responsive, bottomTabBarRect } = storeToRefs(layoutStore)
const openSearchStore = useOpenSearchStore()
const { autoComplete, autoCompleteKeyword } = storeToRefs(openSearchStore)
const isSearchPage = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.SEARCH.name)
})
const isOpen = computed(() => layers.value.includes(LayerType.SEARCH_PANEL))
const isSearchInputFocus = ref(false)
const keyFocusIdx = ref(-1)
const styles = computed(() => {
  const mobile: CSSProperties = {
    height: `calc(100% - ${useGetStyleSize(
      bottomTabBarRect.value?.height || 0
    )})`,
  }

  switch (responsive.value) {
    case 'mobile':
      return mobile
    default:
      return {}
  }
})

const handleOnSearch = useDebounce(
  async (updateKeyword?: string | KeyboardEvent | MouseEvent | TouchEvent) => {
    // 1. 자동 완성 리스트로 부터 주입받는 키워드로 검색시
    if (updateKeyword && typeof updateKeyword === 'string')
      autoCompleteKeyword.value = updateKeyword

    // 2. 검색어 좌우 여백 삭제
    const trimKeyword = autoCompleteKeyword.value.trim()

    // 3. 미입력된 키워드일 경우 토스트 메세지 노출
    if (!trimKeyword || trimKeyword.match(/^ *$/) !== null) {
      autoCompleteKeyword.value = ''
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '검색 > 검색어 미입력시 Toast 노출',
        eventLabel: t('statusMessages.searchKeyword.empty'),
        eventSlot: '검색바',
        eventI18nAddr: useKoreanTranslation(
          'statusMessages.searchKeyword.empty'
        ),
        eventComponent: 'Input',
      })
      useToast(t('statusMessages.searchKeyword.empty'))
      return
    }

    // 4. 새로운 키워드를 기준으로 자동완성 리스트 조회
    await openSearchStore.fetchAutoComplete({
      keyword: trimKeyword,
    })

    // 5. 새로운 자동완성 리스트를 기준으로 포커스 키워드 인덱스 업데이트
    keyFocusIdx.value = autoComplete.value.findIndex(
      (item) => item === trimKeyword
    )

    // 6. 자동완성 하이라이트 싱크를 맞추기위해 키워드 업데이트
    openSearchStore.updateKeyword(trimKeyword)

    // 7. 키워드 검색
    layoutStore['layer:close'](LayerType.SEARCH_PANEL)
    searchStore.updateKeyword(trimKeyword)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '키워드 검색',
      eventLabel: 'search',
      eventSlot: '검색바',
      eventI18nAddr: 'search',
      eventComponent: 'Input',
      searchContents: trimKeyword,
    })
    await useNavigations({
      url: ROUTES.SEARCH.path,
      query: { keyword: trimKeyword },
    })
  },
  300
)

const handleOnClear = () => {
  openSearchStore.clearAutoComplete()
}

/**
 * methods: 키워드 완성 > 자동완성 리스트 조회
 */
const updateAutoCompleteData = useDebounce(async () => {
  openSearchStore.updateKeyword(autoCompleteKeyword.value)
  const incompleteHangleRegex = /[ㄱ-ㅎㅏ-ㅣ]/g

  const cleanedKeyword = autoCompleteKeyword.value
    .replace(incompleteHangleRegex, '')
    .trim()

  if (cleanedKeyword) {
    await openSearchStore.fetchAutoComplete({ keyword: cleanedKeyword })
  }
}, 100)

/**
 * methods: 검색바 키업 > 자동완성 리스트 조회 함수 호출
 */
const handleOnKeyUp = useDebounce((event: KeyboardEvent) => {
  if (event && event.key) {
    const ignoredKeys = ['ArrowUp', 'ArrowDown']
    if (!ignoredKeys.includes(event.key)) {
      updateAutoCompleteData()
    }
  }
}, 200)

/**
 * methods: 검색바 up, down 키 헨들러
 */
const handleOnKeyUpDown = (key: NovaAutoCompleteKeywordsDirection) => {
  const totalItems = autoComplete.value.length

  switch (key) {
    case NovaAutoCompleteKeywordsDirection.down:
      keyFocusIdx.value < totalItems - 1
        ? keyFocusIdx.value++
        : (keyFocusIdx.value = 0)
      break
    case NovaAutoCompleteKeywordsDirection.up:
      keyFocusIdx.value > 0
        ? keyFocusIdx.value--
        : (keyFocusIdx.value = totalItems - 1)
      break
    default:
      throw new Error('nova auto complete keywords direction is not match')
  }

  autoCompleteKeyword.value = autoComplete.value[keyFocusIdx.value]
}

/**
 * methods: 검색바 focus 헨들러
 */
const handleOnFocus = () => {
  isSearchInputFocus.value = true
  layoutStore['layer:open'](LayerType.SEARCH_PANEL)
}

/**
 * methods: 검색바 blur 헨들러
 */
const handleOnBlur = () => {
  setTimeout(() => {
    isSearchInputFocus.value = false
  }, 300)
}

// 검색 페이지 내부에서 변경되는 값 감지 하려고 full path 사용
watch(
  () => route.fullPath,
  () => {
    // 검색 인풋 모델 업데이트
    autoCompleteKeyword.value = isSearchPage.value ? searchStore.keyword : ''
    keyFocusIdx.value = autoComplete.value.findIndex(
      (item) => item === autoCompleteKeyword.value
    )

    // 검색 패널 열려 있을 경우 검색 패널 닫기
    if (layoutStore.layers.includes(LayerType.SEARCH_PANEL)) {
      layoutStore['layer:close'](LayerType.SEARCH_PANEL)
    }
  },
  { immediate: true }
)

watch(
  () => autoCompleteKeyword.value,
  () => {
    if (!autoCompleteKeyword.value) {
      openSearchStore.clearAutoComplete()
    }
  }
)

// 자동완성 패널의 값이 변화하면 자동 완성 창에서 선택된 값을 초기화
watch(
  () => autoComplete.value,
  () => {
    keyFocusIdx.value = -1
  }
)
</script>

<template>
  <div :class="['nova-search-bar', { on: isOpen }]" :style="styles">
    <div class="search-bar-wrap">
      <NovaButtonIcon
        :size="40"
        :icon="{ type: 'outline', name: 'chev-left' }"
        :theme="'circle'"
        :fill-icon="false"
        class="mobile-back-button"
        @click="() => layoutStore['layer:close'](LayerType.SEARCH_PANEL)"
      />

      <NovaInput
        v-model="autoCompleteKeyword"
        :placeholder="t('searchPlaceholder')"
        :type="'search'"
        :theme="'search'"
        @on-focus="handleOnFocus"
        @on-blur="handleOnBlur"
        @on-search="handleOnSearch"
        @on-clear="handleOnClear"
        @keyup="handleOnKeyUp"
        @keydown.up.prevent="
          () => handleOnKeyUpDown(NovaAutoCompleteKeywordsDirection.up)
        "
        @keydown.down.prevent="
          () => handleOnKeyUpDown(NovaAutoCompleteKeywordsDirection.down)
        "
        @keydown.enter.prevent="handleOnSearch"
      >
        <template #tools>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'search' }"
            class="search-button"
            @click="handleOnSearch"
          />
        </template>
      </NovaInput>
    </div>

    <NovaSearchPanel
      :is-search-input-focus="isSearchInputFocus"
      :key-focus-idx="keyFocusIdx"
      @on-search="handleOnSearch"
    />
  </div>
</template>

<style scoped lang="scss">
.nova-search-bar {
  position: relative;

  .search-bar-wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    .mobile-back-button {
      display: none;
    }
  }

  &.on {
    z-index: $z-index-search-panel;

    .search-bar-wrap {
      z-index: $z-index-search-panel + 2;
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: $z-index-search-panel;
    background-color: $color-bg-2;
    opacity: 0;
    pointer-events: none;

    .search-bar-wrap {
      flex-shrink: 0;
      height: 64px;
      padding: 0 20px 0 10px;

      .mobile-back-button {
        display: inline-flex;
      }

      &:deep(.input-group.theme-search) {
        > .inner {
          .input-box {
            background-color: $color-white;
            box-shadow: 0px 2px 4px 0px #00000014;
          }
        }
      }
    }

    &.on {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
