<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'
import type { NovaSearchBarIntroProps } from './NovaSearchBarIntro.types'
import { ROUTES } from '@configs'
import { BannerCode, LayerType } from '@store'

const props = defineProps<NovaSearchBarIntroProps>()
const { isInit } = toRefs(props)
const { t, locale } = useI18n()
// const route = useRoute()
// const isSearchPage = computed(() => {
//   // ROUTES.SEARCH
//   return false
// })
const { cookieNames, setCookie, getCookie } = useCookies()
const layoutStore = useLayoutStore()
const { responsive } = storeToRefs(layoutStore)
const searchStore = useSearchStore()
const openSearchStore = useOpenSearchStore()
const { autoCompleteKeyword } = storeToRefs(openSearchStore)
const postStore = usePostStore()
const { hashtagControl } = storeToRefs(postStore)
const searchKeywords = ref<Array<string>>([])
const creatorStore = useCreatorStore()
const { recommendCreators } = storeToRefs(creatorStore)
const fetchRecommendCreatorsError = ref(false)
const etcStore = useEtcStore()
const { hashtagRankSearchPanel } = storeToRefs(etcStore)
const fetchHashtagsStatus = reactive({
  loading: false,
  error: false,
})
const eventBanners = ref<Array<Banner>>([])
const eventBannersSwiper = ref<SwiperType | null>(null)
const eventBannersSwiperSlide = ref(1)
const lastLocale = ref<String>(locale.value)
const eventBannersSwiperTotalSlides = computed(() => eventBanners.value.length)
const eventBannersSwiperOptions = computed(() => ({
  pagination: {
    clickable: true,
  },
  watchOverflow: true,
  loop: eventBannersSwiperTotalSlides.value > 1,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  modules:
    eventBannersSwiperTotalSlides.value > 1
      ? [Navigation, Pagination, Autoplay]
      : [],
}))
const fetchEventBannersStatus = reactive({
  loading: false,
  error: false,
})

// 최근 검색어: 검색
const handleOnRecentKeywordSearch = async (index: number) => {
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
  const keyword = searchKeywords.value[index]
  searchStore.updateKeyword(keyword)
  await useNavigations({ url: ROUTES.SEARCH.path, query: { keyword } })
  getRecentKeywords()
}

// 최근 검색어: 삭제
const handleOnRecentKeywordRemove = (index: number) => {
  searchKeywords.value.splice(index, 1)
  setCookie(cookieNames.SEARCHES, searchKeywords.value, 30, 'days')
}

// 최근 검색어: 조회
const getRecentKeywords = () => {
  const cookieSearchKeywords = getCookie<string[]>(cookieNames.SEARCHES) || []
  searchKeywords.value = cookieSearchKeywords.slice(0, 10)
}

// 추천 크리에이어: 조회
const fetchRecommendCreators = async () => {
  try {
    fetchRecommendCreatorsError.value = false
    await creatorStore.fetchRecommendCreators({
      payload: { pageSize: 3 },
      refresh: true,
    })
  } catch (err) {
    fetchRecommendCreatorsError.value = true
  }
}

// 해시태그: 조회
const fetchHashtags = async () => {
  if (hashtagRankSearchPanel.value.length) return

  try {
    fetchHashtagsStatus.loading = true
    fetchHashtagsStatus.error = false
    await etcStore.fetchHashtagRanking(
      {
        pageSize: 10,
        rankPeriod: 'semiannual',
      },
      'searchPanel'
    )
  } catch (err) {
    fetchHashtagsStatus.error = true
  } finally {
    fetchHashtagsStatus.loading = false
  }
}

// 해시태그: 검색 결과 가기
const handleOnGoHashtag = (hashtag: Hashtag) => {
  useNavigations({ url: ROUTES.HASHTAG.path, query: { ...hashtag } })
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}

// 이벤트: 배너 조회
const fetchEventBanner = async () => {
  if (eventBanners.value.length && lastLocale.value === locale.value) return

  try {
    fetchEventBannersStatus.loading = true
    fetchEventBannersStatus.error = false
    eventBanners.value = await etcStore.fetchBanners({
      bannerSlotCode: BannerCode.SEARCH_PANEL_EVENT,
    })
    lastLocale.value = locale.value
  } catch (err) {
    fetchEventBannersStatus.error = true
  } finally {
    fetchEventBannersStatus.loading = false
  }
}

// 이벤트: 배너 스와이프 초기화
const onSwiper = (swiperInstance: SwiperType) => {
  eventBannersSwiper.value = swiperInstance
}

// 이벤트: 배너 슬라이드 변경
const onSlideChange = () => {
  if (eventBannersSwiper.value) {
    eventBannersSwiperSlide.value = eventBannersSwiper.value.realIndex + 1
  }
}

// 이벤트: 이동
const handleOnGoEventBanner = (banner: Banner) => {
  if (banner.bannerLinkAt === 'Y') {
    useNavigations({
      url: banner.bannerLinkUrl,
      external: banner.bannerExtrlLinkAt === 'Y',
    })
    layoutStore['layer:close'](LayerType.SEARCH_PANEL)
  }
}

watch(
  () => isInit.value,
  (cur) => {
    if (cur) {
      getRecentKeywords()
      fetchRecommendCreators()
      fetchHashtags()
      fetchEventBanner()
    }
  }
)
</script>

<template>
  <div class="nova-search-bar-intro">
    <!-- 1. 최근 검색어 -->
    <ClientOnly>
      <div
        v-if="searchKeywords.length"
        class="intro-box recent-search-keywords"
      >
        <h4 class="intro-title">{{ t('recentSearches') }}</h4>
        <div class="intro-content">
          <NovaBoxRecentSearchesItem
            v-for="(item, index) in searchKeywords"
            :key="index"
            :keyword="item"
            :theme="'badge'"
            :on="item === autoCompleteKeyword"
            @on-search="handleOnRecentKeywordSearch(index)"
            @on-remove="handleOnRecentKeywordRemove(index)"
          />
        </div>
      </div>
    </ClientOnly>

    <!-- 2. 추천 크리에이터 -->
    <div
      v-if="!fetchRecommendCreatorsError"
      class="intro-box recommend-creators"
    >
      <h4 class="intro-title">
        {{ t('subscribe.creatorsByRecommended.title') }}
      </h4>

      <NovaLoadingIndicator
        v-if="recommendCreators.loading"
        :size="60"
        :bg-bright="'light'"
        :style="{ minHeight: '100px' }"
      />

      <div v-if="!recommendCreators.loading" class="intro-content">
        <NovaCreatorProfileCardTiny
          v-for="creator in recommendCreators.items.creatorList"
          :key="creator.userSn"
          :creator="creator"
          :force-mobile-layout="true"
          :on-go-creator-after="
            () => layoutStore['layer:close'](LayerType.SEARCH_PANEL)
          "
        />
      </div>
    </div>

    <!-- 3. 해시태그 -->
    <div v-if="!fetchHashtagsStatus.error" class="intro-box hashtag">
      <h4 class="intro-title">#{{ t('hashtag') }}</h4>

      <NovaLoadingIndicator
        v-if="fetchHashtagsStatus.loading"
        :size="60"
        :bg-bright="'light'"
        :style="{ minHeight: '100px' }"
      />

      <div class="intro-content">
        <NovaHashtags
          :hashtags="hashtagRankSearchPanel"
          :prefix="'#'"
          :able-action="true"
          :select-hashtag="hashtagControl.hashtag"
          @on-change-active-hashtag="handleOnGoHashtag"
        />
      </div>
    </div>

    <!-- 4. 이벤트 배너 -->
    <div v-if="!fetchEventBannersStatus.error" class="intro-box event-banner">
      <h4 class="intro-title">#{{ t('event') }}</h4>
      <div class="intro-content">
        <Swiper
          v-if="eventBanners.length"
          v-bind="eventBannersSwiperOptions"
          :pagination="{
            clickable: true,
            el: '.swiper-pagination',
            type: 'custom',
          }"
          @swiper="onSwiper"
          @slide-change="onSlideChange"
        >
          <SwiperSlide v-for="banner in eventBanners" :key="banner.bannerSn">
            <NovaImageContainer
              :key="
                responsive === 'mobile'
                  ? banner.bannerMobileImageUrl
                  : banner.bannerImageUrl
              "
              :image-url="
                responsive === 'mobile'
                  ? banner.bannerMobileImageUrl
                  : banner.bannerImageUrl
              "
              :ratio="'335:84'"
              class="event-banner-image"
              @click="() => handleOnGoEventBanner(banner)"
            />
          </SwiperSlide>
        </Swiper>
        <div
          v-if="eventBannersSwiperTotalSlides >= 2"
          class="swiper-pagination"
        >
          {{ eventBannersSwiperSlide }} / {{ eventBannersSwiperTotalSlides }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nova-search-bar-intro {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  height: 100%;
  max-height: inherit;
  overflow-y: overlay;
  //overflow-y: visible;
  //padding: 20px 20px 30px;

  @include mobile {
    gap: 24px;
    padding: 20px 20px 30px;
  }

  .intro-box {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include mobile {
      gap: 12px;
    }

    .intro-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: -0.025em;
      text-align: left;
    }

    .intro-content {
    }

    &.recent-search-keywords {
      @include mobile {
        padding: 12px 12px 14px;
        background-color: $color-white;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 hex-to-rgba($color-black, 0.08);
      }

      .intro-content {
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
        row-gap: 10px;
      }
    }

    &.recommend-creators {
      .intro-content {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        -webkit-grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 16px;
      }
    }

    &.hashtag {
      @include mobile {
        padding: 12px 12px 14px;
        background-color: $color-white;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 hex-to-rgba($color-black, 0.08);
      }
    }

    &.event-banner {
      .intro-content {
        position: relative;
        border-radius: 8px;
        overflow: hidden;

        .event-banner-image {
          cursor: pointer;
        }

        .swiper-pagination {
          width: 50px;
          bottom: 8px;
          left: auto;
          right: 8px;
          padding: 2px 6px;
          font-size: 14px;
          color: $color-bg-3;
          border-radius: 12px;
          background-color: $color-black-30p;
        }
      }
    }
  }
}
</style>
